import React, { Suspense } from "react";
import ArticleContent from "../components/ArticleContent";
import Loading from "../components/Loading";
import { useParams } from "react-router-dom";

export default function Article() {
    const { hashedId } = useParams();

    return (
        <Suspense fallback={<Loading />}>
           
            <ArticleContent hashedId={hashedId} />
        </Suspense>
    );
}
