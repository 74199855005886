'use client'


import React, { useState, useEffect } from 'react'
import { ArrowUpRight, Search } from 'lucide-react'
import { Helmet } from 'react-helmet';


import ArticleList from '../components/ArticleList'
import Sidebar from '../components/Sidebar'
import CategoryDropdown from '../components/CategoryDropdown'
import ViewToggle from '../components/ViewToggle'
import axios from 'axios';
import Loading from '../components/Loading';


export default function Home() {
    const apiUrl = process.env.REACT_APP_BACKEND_URL;
    const siteName = process.env.REACT_APP_BACKEND_NAME;

    const [view, setView] = useState(localStorage.getItem('view'))
    const [Categories, setCategories] = useState([])
    const [Blogs, setBlogs] = useState([])
    const [HyperLinks, setHyperLinks] = useState([])
    const [website, setwebsite] = useState([])
    const [searchItem, setSearchItem] = useState(null)

    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [CategorySelected, setCategorySelected] = useState([])

    const [loading, setLoading] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                await getCategories();
                await getBlogs(null, currentPage);
                await getHyperLink();
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [CategorySelected, currentPage]);


    const getCategories = async () => {
        try {
            const response = await axios.get(`${apiUrl}/categories/search_temp/${siteName}`);
            const responseData = response.data;

            if (responseData) {

                setCategories(responseData.data);
            }
        } catch (error) {
            console.error(error.response?.data?.message || 'An error occurred while fetching the categories.');
        }
    };

    const getHyperLink = async (page = 1) => {
        try {
            const response = await axios.post(`${apiUrl}/hyperLinks/searchHyperLinks/${siteName}?page=${page}`, { category: CategorySelected });
            const responseData = response.data;

            if (responseData) {

                setHyperLinks(responseData.data);
            }
        } catch (error) {
            console.error(error.response?.data?.message || 'An error occurred while fetching the categories.');
        }
    };

    const getBlogs = async (search = null, page = 1) => {
        try {
            const response = await axios.post(
                `${apiUrl}/blogs/searchBlogs/${siteName}?page=${page}`,
                { category: CategorySelected, searchItem: search }
            );
            const responseData = response.data;

            if (responseData) {
                setBlogs((prevBlogs) => {
                    const allBlogs = page === 1 ? responseData.data : [...prevBlogs, ...responseData.data];
                    const uniqueBlogs = Array.from(new Map(allBlogs.map((blog) => [blog.id, blog])).values());
                    return uniqueBlogs;
                });
                setCurrentPage(responseData.current_page);
                setHasMore(responseData.current_page < responseData.last_page);
                setwebsite(responseData.website);
            }
        } catch (error) {
            console.error(error.response?.data?.message || 'An error occurred while fetching the blogs.');
        }
    };


    const reset = () => {
        setSearchItem('');
        getBlogs(null) // Clear the search input
    };


    const loadMore = () => {
        if (hasMore) {
            getBlogs(null, currentPage + 1);
        }
    };

    const setViewFunction = (view) => {
        localStorage.setItem('view' , view)
        setView(view)
    }

    return (
        <div className="min-h-screen  bg-white">
            {/* Header */}
            <Helmet>
                <title>Expert Insights on SEO, Link Building, and Digital Growth | Smart Traffic Boost Blog</title>
                <meta name="description" content='Discover actionable tips, strategies, and updates on SEO, link building, and website optimization. Explore our expert blog to boost your traffic and grow your online presence today' />
            </Helmet>



            {/* Main Content */}
            <main className="max-w-7xl mx-auto px-4 py-8">
                {/* Search and Topics */}
                <div className="mb-8 flex w-full gap-2">
                    <div className="relative w-full">
                        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                        <input
                            type="search"
                            placeholder="Search articles..."
                            value={searchItem || ''}
                            onChange={(e) => setSearchItem(e.target.value)}
                            className="w-full pl-10 px-4 text-sm h-10 border-neutral-200 border rounded-lg"
                        />
                    </div>
                    <button
                        onClick={() => getBlogs(searchItem)}
                        disabled={!searchItem}
                        className='flex text-sm justify-center items-center px-3 h-10 bg-black hover:bg-black/80 rounded-lg text-white transition-all duration-400'
                    >
                        Search
                    </button>
                    <button
                        onClick={reset}
                        className='flex text-sm justify-center items-center px-3 h-10 bg-black hover:bg-black/80 rounded-lg text-white transition-all duration-400'
                    >
                        Reset
                    </button>
                </div>


                {/* Content Grid */}
                <div className={`grid ${HyperLinks.length > 0 ? 'lg:grid-cols-[1fr,350px] ' : ''} gap-8`}>
                    <div className='grid w-full'>
                        <div className="flex items-center justify-between mb-8">
                            <CategoryDropdown setCategorySelected={setCategorySelected} categories={Categories} />
                            <ViewToggle view={view} setView={setViewFunction} />
                        </div>
                        {loading && currentPage == 1 ?
                            <div className="p-4  h-full rounded-lg">
                                <Loading text="Please wait..." />
                            </div>
                            :
                            <ArticleList hyperLinks={HyperLinks.length > 0} hasMore={hasMore} loadMore={loadMore} loading={loading} articles={Blogs} view={view} setView={setViewFunction} />
                        }
                    </div>
                    {HyperLinks.length > 0 &&

                        <Sidebar businesses={HyperLinks} loading={loading} />

                    }
                </div>
            </main>

        </div>
    )
}
