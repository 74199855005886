import React from 'react';
import { ArrowUpRight, CalendarDays, Loader } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Img } from 'react-image';
import Loading from './Loading';

const ArticleList = ({ hyperLinks, articles, loading, hasMore, loadMore }) => {
  const formatDate = (inputDate) => {
    return new Date(inputDate).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const secretKey = process.env.REACT_APP_CRYPT_SECRET;

  const hashId = (id) => {
    return btoa(id.toString() + secretKey);
  };

  const view = localStorage.getItem('view');

  const gridDisplay = () => {
    if (!hyperLinks && view === 'grid') return 'grid md:grid-cols-3';
    if (hyperLinks && view === 'grid') return 'grid md:grid-cols-2';
    if (!hyperLinks && view !== 'grid') return 'grid md:grid-cols-2';
    if (hyperLinks && view !== 'grid') return 'space-y-4';
  };

  return (
    <div className={` gap-4 w-full ${gridDisplay()}`}>
      {articles.map((article, index) => (
        <ArticleCard key={index} article={article} view={view} hashId={hashId} formatDate={formatDate} />
      ))}
      {loading && (
        <div className="p-4 border lg:col-span-2 h-full rounded-lg">
          <Loading text="Please wait..." />
        </div>
      )}
      {hasMore && (
        <LoadMoreButton loadMore={loadMore} />
      )}
    </div>
  );
};

const ArticleCard = ({ article, view, hashId, formatDate }) => (
  <article className={`group w-full border border-neutral-200 rounded-xl flex cursor-pointer ${view === 'grid' ? 'flex-col' : 'items-start w-full lg:flex-row'}`}>
    <ArticleImage article={article} view={view} />
    <ArticleContent article={article} view={view} hashId={hashId} formatDate={formatDate} />
  </article>
);

const ArticleImage = ({ article, view }) => (
  <div className={`relative overflow-hidden rounded-xl ${view === 'grid' ? 'aspect-[16/9] w-full' : 'h-full lg:aspect-square w-2/5'}`}>
    {article?.image ? (
      <Img
        src={article.image}
        alt={article.title}
        width={400}
        height={400}
        className="transition-transform h-full w-full object-cover duration-300 group-hover:scale-105"
      />
    ) : (
      <div className="transition-transform w-full h-full flex justify-center items-center duration-300 group-hover:scale-105">
        <span className="text-gray-400">No image available</span>
      </div>
    )}
    <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black/60"></div>
  </div>
);

const ArticleContent = ({ article, view, hashId, formatDate }) => (
  <div className={`m-4 grid gap-3 ${view === 'list' ? 'lg:ml-6 lg:w-2/3 w-3/5' : ''}`}>
    <div className="flex justify-between items-center gap-2">
      <div className="flex items-center justify-start lg:text-xs gap-1 text-[10px]">
        <CalendarDays size={15} />
        <span>{formatDate(article?.schedule_at)}</span>
      </div>
      <span className="px-3 py-1 lg:text-xs text-[10px] font-medium bg-black/10 rounded-full">
        {article.category.name}
      </span>
    </div>
    <h2 className={`lg:text-lg text-md ${view === 'grid' ? 'line-clamp-1' : 'lg:line-clamp-5 line-clamp-1'} font-semibold transition-colors`}>
      {article.title}
    </h2>
    <p className={`text-gray-600 ${view === 'grid' ? 'line-clamp-2' : 'lg:line-clamp-5 line-clamp-2'} text-sm`}>
      {article.description}
    </p>
    <Link to={`/Post/${hashId(article.id)}`} className="flex items-center text-xs uppercase text-blue-600 font-medium">
      Read post
      <ArrowUpRight className="ml-1 h-4 w-4" />
    </Link>
  </div>
);

const LoadMoreButton = ({ loadMore }) => (
  <div className="flex justify-center lg:col-span-2 w-full">
    <button
      onClick={loadMore}
      className="px-4 py-2 flex justify-center items-center gap-2 text-xs uppercase border border-neutral-800 rounded-full transition-all duration-300 hover:text-white hover:bg-neutral-800 text-black"
    >
      <Loader size={16} />
      <span>Load More</span>
    </button>
  </div>
);

export default ArticleList;

