
import React ,{ useState, useEffect } from 'react';
import { Check, ChevronDown, X } from 'lucide-react';

export default function CategoryDropdown({ setCategorySelected, categories = [] }) {
    const [open, setOpen] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [searchValue, setSearchValue] = useState(""); 
    const [filteredCategories, setFilteredCategories] = useState(categories);

    const filterOptions = (value) => {
        if (value.length > 2) {
            setFilteredCategories(categories.filter(item => item.name.toLowerCase().includes(value.toLowerCase())));
        } else {
            setFilteredCategories(categories); // Reset when value is short or empty
        }
    };

    const handleSelect = (categoryId) => {
        setSelectedCategoryId(categoryId);
        setCategorySelected(categoryId);
        setOpen(false);
        setSearchValue(""); // Clear search value after selection
    };

    useEffect(() => {
        filterOptions(searchValue);
    }, [searchValue, categories]); 

    return (
        <div className="relative">
            <button
                onClick={() => setOpen(!open)}
                className="w-[200px] relative px-2 py-2 border z-10 text-sm border-gray-300 rounded-md flex justify-between items-center"
            >
                <div className='flex  justify-start gap-2 items-center'>
                    {selectedCategoryId && <a onClick={() => {setSelectedCategoryId(null) ; setCategorySelected(null)}} className='bg-neutral-50 absolute z-50 rounded-full p-1 flex justify-center border border-neutral-200 items-center text-neutral-600'><X size={12} /></a>}
                    <span className={`${selectedCategoryId ? 'ml-8' : ''}`}>{categories.find((category) => category.id === selectedCategoryId)?.name || "Select category..."}</span>

                </div>
                <ChevronDown className="ml-2 h-4 w-4 opacity-50" />
            </button>

            {open && (
                <div className="absolute w-[200px] mt-1 bg-white border border-gray-300 rounded-md shadow-lg z-10">
                    <input
                        type="text"
                        placeholder="Search category..."
                        onChange={(e) => setSearchValue(e.target.value)} // Update search value
                        value={searchValue} // Bind the value to the state
                        className="w-full px-4 text-sm py-2 border-b border-gray-300 focus:outline-none"
                    />
                    <ul className="max-h-60 overflow-y-auto">
                        {filteredCategories.length === 0 ? (
                            <li className="px-2 py-2 text-sm text-gray-500">No categories available</li>
                        ) : (
                            filteredCategories.map((category) => (
                                <li
                                    key={category.id}
                                    onClick={() => handleSelect(category.id)}
                                    className={`px-2 py-2 cursor-pointer hover:bg-gray-100 ${selectedCategoryId === category.id ? 'bg-gray-200' : ''}`}
                                >
                                    <div className="flex w-full text-sm items-center">
                                        <Check
                                            className={`mr-2 h-4 w-4 ${selectedCategoryId === category.id ? 'opacity-100' : 'opacity-0'}`}
                                        />
                                        {category.name}
                                    </div>
                                </li>
                            ))
                        )}
                    </ul>
                </div>
            )}
        </div>
    );
}
