import { BrowserRouter as Router, Route, Routes, Link, BrowserRouter } from 'react-router-dom';
import Home from './pages/Home';
import Post from './pages/Post';
import NotFound from './pages/NotFound';
import Navbar from './components/Navbar';
import NewsletterFooter from './components/NewsletterFooter';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for Toastify

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />

      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Post/:hashedId" element={<Post />} />
        <Route path="/*" element={<NotFound />} />

      </Routes>
      <NewsletterFooter />
    </BrowserRouter>
  );
}

export default App;
