import * as React from "react"
import { Loader2 } from 'lucide-react'

export default function Loading({ size = "md", text = "Loading", className, ...props }) {


  return (
    <div
      className='flex flex-col items-center justify-center space-y-2'
    >
      <div className="relative">
        <Loader2 className="animate-spin text-muted-foreground h-8 w-8" />
        <div className="absolute inset-0 rounded-full animate-ping h-8 w-8 opacity-75 bg-muted-foreground" />
      </div>
      <p className="text-sm text-muted-foreground animate-pulse">{text}</p>
    </div>
  )
}

