
import { LayoutGrid, List } from 'lucide-react'
import React , { useState } from 'react'

export default function ViewToggle({view, setView}) {

  return (
    <div className="flex items-center gap-2 bg-gray-100 p-1 rounded-lg">
      <button
        onClick={() => setView('grid')}
        className={`w-8 h-8 flex justify-center items-center rounded ${view === 'grid' ? 'text-neutral-100 bg-neutral-800' : ''}`  }
      >
        <LayoutGrid className="h-4 w-4" />
      </button>
      <button
        size="sm"
        onClick={() => setView('list')}
        className={`w-8 h-8 flex justify-center items-center rounded ${view === 'grid' ? '' : 'text-neutral-100 bg-neutral-800'}`  }
      >
        <List className="h-4 w-4" />
      </button>
    </div>
  )
}
