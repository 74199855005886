import { ArrowUpRight } from 'lucide-react'
import { Link } from 'react-router-dom';
import React from 'react'

function Navbar() {
        const siteName = process.env.REACT_APP_SITE_NAME;

    return (
        <header className="border-b bg-white">
            <div className="max-w-7xl  mx-auto px-4 py-4 flex items-center justify-between">

                <Link to='/' className="lg:text-2xl text-lg font-bold text-start">{siteName}</Link>
                <div className="flex items-center gap-4">
                    <a href="https://app.smarttrafficboost.com" target='_black'
                        className="flex items-center gap-2 px-4 py-2 rounded-full bg-neutral-900 text-white lg:text-sm text-xs hover:bg-neutral-700 transition-colors">
                        <span>Try Dashboard</span>
                        <ArrowUpRight className=" h-4 w-4" />

                    </a>

                </div>
            </div>
        </header>)
}

export default Navbar