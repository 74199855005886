import React from 'react'
import { ArrowUpRight, Link2 } from 'lucide-react'
import Loading from './Loading'

export default function Sidebar({ businesses, loading }) {
    return (
        <aside className="space-y-8">
            {businesses.length > 0 &&
                <>

                    {/* Premium CTA */}
                    <div className="p-6 bg-neutral-50 rounded-xl border border-neutral-200">
                        <h3 className="text-xl font-bold mb-2">Boost Your Website’s Rankings with Quality Backlinks</h3>
                        <p className="text-gray-600 mb-4">Affordable plans starting at less than $5.</p>
                        <a href='https://www.smarttrafficboost.com/' target='_blank' className=" mx-auto text-xs mb-2 uppercase rounded-full w-full px-5 py-2 text-white bg-neutral-900 hover:bg-neutral-700">Get Started Now</a>
                    </div>


                    {/* Business Directory */}
                    <div className="bg-white relative rounded-xl border max-h-[500px] overflow-y-auto">
                        <div className='bg-white p-6  w-full sticky top-0'>
                            <h3 className="text-lg font-semibold ">Featured Businesses</h3>

                        </div>

                        {loading ?
                            <div className="p-4  h-full rounded-lg">
                                <Loading text="Please wait..." />
                            </div>
                            :
                            <div className="space-y-6 p-6 ">
                                {businesses.map((business) => {
                                    return (
                                        <a
                                            key={business.id}
                                            href={business.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="flex items-center gap-4 group"
                                        >
                                            <div className="flex-shrink-0 w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center">
                                                <Link2 className="h-5 w-5 text-blue-600" />
                                            </div>
                                            <div className="flex-1 items-center min-w-0">
                                                <div className="flex items-center gap-2">
                                                    <h4 className="font-medium text-gray-900 line-clamp-1 text-sm group-hover:text-blue-600 transition-colors">
                                                        {business.anchor}
                                                    </h4>
                                                    <ArrowUpRight className="h-4 w-4 text-gray-400 group-hover:text-blue-600 transition-colors" />
                                                </div>
                                                <p className="text-xs line-clamp-1 text-gray-500">{business?.description}</p>
                                            </div>
                                        </a>
                                    )
                                })}
                            </div>
                        }

                    </div>
                </>
            }

        </aside>

    )
}

