
import React, { useState, useEffect, Suspense } from "react"
import { Clock, Calendar, Home, ArrowRight, ChevronRight } from 'lucide-react'
import axios from "axios"
import { Link } from "react-router-dom"
import Loading from "./Loading"
import { Img } from "react-image"
import { Helmet } from 'react-helmet';

export default function ArticleContent({ hashedId }) {

    const apiUrl = process.env.REACT_APP_BACKEND_URL;
    const siteName = process.env.REACT_APP_BACKEND_NAME;

    const [postData, setpostData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true)
        const fetchpostData = async () => {
            if (hashedId) {
                try {
                    const response = await axios.get(`${apiUrl}/blogs/FindBlog/${siteName}/${hashedId}`);
                    const responseData = response.data;

                    if (responseData) {
                        setpostData(responseData.data);
                    }
                } catch (error) {
                    setLoading(false);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchpostData();
    }, [hashedId]);



    const formatDate = (inputDate) => {
        return new Date(inputDate).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };
    return (
        <div className="max-w-7xl bg-white mx-auto px-4 py-8">

            {
                loading ?
                    <div className="p-4 border col-span-2 h-full rounded-lg">
                        <Loading text="Please wait..." />
                    </div>
                    :
                    <>
                        <Helmet>
                            <title>{postData?.meta_title}</title>
                            <meta name="description" content={postData?.meta_description} />
                        </Helmet>
                        <div className="flex justidy-start mb-3 items-center gap-2 ">
                            <Link to='/' className="flex justify-start items-center gap-2 text-sm text-neutral-800"><span><Home size={16} /></span> <span>Home</span></Link>
                            <ChevronRight size={16} className="text-neutral-800" />
                            <p className="flex justify-start items-center gap-2 text-sm text-neutral-600">Article</p>

                        </div>
                        <div className="flex justify-between items-center mb-3">
                            <h1 className="lg:text-2xl text-xl font-bold">{postData?.title}</h1>
                        </div>
                        <p className="text-md   mb-5">{postData?.description}</p>
                        <div className="mb-5 w-full ">
                            {postData?.image ? (
                                <Img
                                    src={postData.image}
                                    alt={postData.title}
                                    width={800}
                                    height={400}
                                    className="rounded-lg w-full lg:aspect-[16/7] aspect-square object-cover"
                                />
                            ) : (
                                <div className="rounded-lg w-full lg:aspect-[16/7] aspect-square bg-gray-200 flex items-center justify-center">
                                    <span className="text-gray-400">No image available</span>
                                </div>
                            )}
                        </div>
                        <div className="flex items-center space-x-4 mb-6">
                            <div className="flex items-center text-sm text-muted-foreground">
                                <Calendar className="mr-1 h-4 w-4" />
                                <time className="border-r-2 border-neutral-400 pr-3" dateTime={postData?.schedule_at}>{formatDate(postData?.schedule_at)}</time>
                                <div className="flex items-center pl-3 gap-2">
                                    <Clock className="h-4 w-4 " />
                                    <span>5 min read</span>
                                </div>

                            </div>
                            <div className="flex justify-center items-center px-3 py-1 text-xs bg-neutral-100 rounded-md border border-neutral-200 uppercase">{postData?.category.name}</div>
                        </div>
                        <article
                            className=" max-w-none text-md leading-[30px]"
                            dangerouslySetInnerHTML={{ __html: postData?.content }}
                        />

                    </>
            }

        </div>
    )
}


