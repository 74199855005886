
import React, { useState } from 'react'
import axios from "axios"
import { toast } from 'react-toastify';
import { Loader2 } from 'lucide-react'

export default function NewsletterFooter() {
  const apiUrl = process.env.REACT_APP_BACKEND_URL;
  const siteName = process.env.REACT_APP_BACKEND_NAME;

  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      const response = await axios.post(`${apiUrl}/newsLetter/create/${siteName}`, { email: email });
      const responseData = response.data;

      if (responseData) {
        toast.success(responseData.message);
        setEmail('')
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  return (
    <footer className="bg-gray-50 border-t">
      <div className="container mx-auto px-4 py-12">
        <div className="max-w-xl mx-auto text-center">
          <h2 className="text-2xl font-bold mb-2">Subscribe to our newsletter</h2>
          <p className="text-gray-600 mb-6">
            Get the latest articles and insights from Reader delivered to your inbox.
          </p>
          <form onSubmit={handleSubmit} className="flex gap-2">
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="flex-1 px-4  h-10 border-neutral-200 border rounded-lg "
              required
            />
            <button onClick={(e) => handleSubmit(e)} disabled={loading} className='flex text-sm justify-center items-center px-3 h-10 bg-black hover:bg-black/80 rounded-lg text-white transition-all duration-400'>
              {loading ? (
                <Loader2 className="animate-spin text-white h-5 w-5" />
              ) : (
                'Subscribe'
              )}
            </button>
          </form>
        </div>
      </div>
    </footer>
  )
}

